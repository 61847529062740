.container {
  display: flex;
  max-width: 568px;
  padding: 32px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.subTitle {
  color: var(--cui-fg-subtle);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.iconWrapper {
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px;
  background: var(--cui-bg-subtle);
  width: 36px;
  height: 36px;
}

.icon {
  width: auto;
  justify-content: center;
  height: auto;
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .container {
    display: flex;
    max-width: 568px;
    padding: 32px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }
}
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .container {
    display: flex;
    max-width: 568px;
    padding: 32px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1760px) {
  .container {
    display: flex;
    max-width: 568px;
    padding: 56px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 26px;
    align-self: stretch;
  }
}
@media screen and (min-width: 1760px) {
  .container {
    display: flex;
    max-width: 568px; 
    padding: 56px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }
}